import React, { useCallback, useMemo, useState } from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ExperienceList from "./experienceList";
import CT from "../generic/ct";
import DeliverTrade from "../generic/delivertrade";
import Embelton from "../generic/embelton";
import Panel from "../generic/panel";

import "./styles/experience.css";

const Experience = () => {
	const [selectedExperience, setSelectedExperience] = useState("ct");

	const handleSetSelectedWork = useCallback((experience) => {
		setSelectedExperience(experience);
	}, []);

	const experienceList = useMemo(
		() => (
			<ExperienceList
				selectedExperience={selectedExperience}
				handleOnClick={handleSetSelectedWork}
			/>
		),
		[selectedExperience, handleSetSelectedWork]
	);

	const experienceContent = useMemo(() => {
		switch (selectedExperience) {
			case "ct":
				return (
					<div className="works-right">
						<CT />
					</div>
				);
			case "delivertrade":
				return (
					<div className="works-right">
						<DeliverTrade />
					</div>
				);
			case "embelton":
				return (
					<div className="works-right">
						<Embelton />
					</div>
				);
			default:
				return null;
		}
	}, [selectedExperience]);

	return (
		<Panel shaded className="card">
			<div className="card-header">
				<div className="card-icon">
					<FontAwesomeIcon icon={faBriefcase} />
				</div>
			</div>
			<div className="works-body">
				{experienceList}
				{experienceContent}
			</div>
		</Panel>
	);
};

export default Experience;
