const INFO = {
	main: {
		title: "Adrian Marotta",
		name: "Adrian Marotta",
		email: "amarotta.pro@gmail.com",
		logo: "../logo.jpg",
	},

	socials: {
		linkedin: "https://www.linkedin.com/in/adrian-marotta/",
		instagram:
			"https://www.instagram.com/codingwithadrian?igsh=MTh3OW96eG5oeDUwNA==",
	},

	homepage: {
		description:
			"A passionate Full Stack Developer with a soul that thrives on both code and coffee. I am proficient in shaping innovative solutions from start-up ventures to established projects. My playground? The dynamic realm of JavaScript - constantly evolving, where collaboration is key, and where I continuously innovate, crafting experiences that leave a lasting impact. Beyond the confines of screens, I often seek solace in the serenity of camping, immersing myself in the natural world. My interests gravitate towards the vast expanse of space and the evolving landscape of AI, each offering intriguing avenues for exploration and discovery. My growth mindset sees challenges as opportunities for learning, propelling me towards mastery. Driven by both technical excellence and a thirst for knowledge, I seek a collaborative environment where I can contribute my expertise and grow alongside like-minded individuals.",
	},

	projects: [
		{
			title: "My Mind Check",
			description:
				"Empowering Australian schools through an innovative platform, this tool utilises data-driven insights to proactively identify students' mental health needs.",
			logo: "../mmc.png",
			linkText: "View Project",
			link: "https://mymindcheck.org.au/",
		},

		{
			title: "Bloum",
			description:
				"A transformative integrated platform, providing educators with comprehensive insights into student growth, correlated data analysis, and evidence-based interventions for optimised learning outcomes.",
			logo: "../bloum.jpeg",
			linkText: "View Project",
			link: "https://bloum.ai/",
		},

		{
			title: "DeliverTrade",
			description:
				"Developed an internal automation tool deployed on MacOS and Windows, streamlining workflows and delivering significant time and cost savings.",
			logo: "../delivertrade.jpeg",
			linkText: "View Project",
			link: "https://www.delivertrade.com/",
		},
	],
};

export default INFO;
