import React from "react";

import Project from "./project";

import INFO from "../../data/user";

import "./styles/projects.css";

const Projects = () => {
	const title = (
		<div className="title projects-title">
			Here's a peek at the cool stuff I've helped bring to life{" "}
			{"\u{1F680}"}
		</div>
	);

	return (
		<div>
			{title}
			<div className="all-projects-container">
				{INFO.projects.map((project, index) => (
					<div className="all-projects-project" key={index}>
						<Project
							logo={project.logo}
							title={project.title}
							description={project.description}
							linkText={project.linkText}
							link={project.link}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default Projects;
