import React from "react";

import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";

import INFO from "../../data/user";

import "./styles/about.css";

const About = () => {
	return (
		<React.Fragment>
			<div className="homepage-first-area">
				<div className="homepage-first-area-left-side">
					<div className="homepage-title-secondary">
						I'm Adrian and here's a little about me.
					</div>

					<div className="subtitle homepage-subtitle">
						{INFO.homepage.description}
					</div>
				</div>

				<div className="homepage-first-area-right-side">
					<div className="homepage-image-container">
						<div className="homepage-image-wrapper">
							<img
								src="working.png"
								alt="about"
								className="homepage-image"
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="homepage-socials">
				<a
					href={INFO.socials.linkedin}
					target="_blank"
					rel="noreferrer"
				>
					<FontAwesomeIcon
						icon={faLinkedin}
						className="homepage-social-icon"
					/>
				</a>
				<a
					href={INFO.socials.instagram}
					target="_blank"
					rel="noreferrer"
				>
					<FontAwesomeIcon
						icon={faInstagram}
						className="homepage-social-icon"
					/>
				</a>
				<a
					href={`mailto:${INFO.main.email}`}
					target="_blank"
					rel="noreferrer"
				>
					<FontAwesomeIcon
						icon={faMailBulk}
						className="homepage-social-icon"
					/>
				</a>
			</div>
		</React.Fragment>
	);
};

export default About;
