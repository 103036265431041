import React from "react";
import PropTypes from "prop-types";

const Panel = ({ shaded, children }) => {
	const panelStyle = {
		border: "1px solid #ddd",
		borderRadius: "5px",
		boxShadow: shaded ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none",
		backgroundColor: shaded ? "#fff" : "transparent",
		padding: "15px",
		marginBottom: "20px",
	};

	return <div style={panelStyle}>{children}</div>;
};

Panel.propTypes = {
	shaded: PropTypes.bool,
	children: PropTypes.node,
};

Panel.defaultProps = {
	shaded: false,
};

export default Panel;
