import { TagGroup, Tag } from "rsuite";

import "./styles/skills.css";

const About = () => {
	const left = (
		<div className="left">
			<img src="./worker.jpg" alt="about" className="skills-image" />
		</div>
	);

	const frontend = (
		<div className="skills-section">
			<span className="skills-title">Frontend</span>
			<TagGroup className="skills-tag-group">
				<Tag className="skills-tag" size="lg">
					React
				</Tag>
				<Tag className="skills-tag" size="lg">
					React Native
				</Tag>
				<Tag className="skills-tag" size="lg">
					JavaScript
				</Tag>
				<Tag className="skills-tag" size="lg">
					TypeScript
				</Tag>
				<Tag className="skills-tag" size="lg">
					HTML5
				</Tag>
				<Tag className="skills-tag" size="lg">
					CSS3 / Sass
				</Tag>
			</TagGroup>
		</div>
	);

	const backend = (
		<div className="skills-section">
			<span className="skills-title">Backend</span>
			<TagGroup className="skills-tag-group">
				<Tag className="skills-tag" size="lg">
					NodeJS
				</Tag>
				<Tag className="skills-tag" size="lg">
					ExpressJS
				</Tag>
				<Tag className="skills-tag" size="lg">
					GraphQL
				</Tag>
				<Tag className="skills-tag" size="lg">
					PostgreSQL
				</Tag>
				<Tag className="skills-tag" size="lg">
					Java
				</Tag>
			</TagGroup>
		</div>
	);

	const infrastructure = (
		<div className="skills-section">
			<span className="skills-title">Infrastructure and Practices</span>
			<TagGroup className="skills-tag-group">
				<Tag className="skills-tag" size="lg">
					Micro-service Architecture
				</Tag>
				<Tag className="skills-tag" size="lg">
					AWS
				</Tag>
				<Tag className="skills-tag" size="lg">
					Docker/Kubernetes
				</Tag>
				<Tag className="skills-tag" size="lg">
					Agile
				</Tag>
				<Tag className="skills-tag" size="lg">
					CI/CD
				</Tag>
				<Tag className="skills-tag" size="lg">
					TDD
				</Tag>
				<Tag className="skills-tag" size="lg">
					Version Control
				</Tag>
			</TagGroup>
		</div>
	);

	const right = (
		<div className="right">
			{frontend}
			{backend}
			{infrastructure}
		</div>
	);

	return (
		<div className="skills-container">
			{left}
			{right}
		</div>
	);
};

export default About;
