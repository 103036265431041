import React, { useState } from "react";

import "./styles/navBar.css";
import { useEffect } from "react";

const NavBar = () => {
	const [active, setActive] = useState("about");

	useEffect(() => {
		const handleScroll = () => {
			const aboutSection = document.getElementById("about");
			const projectsSection = document.getElementById("projects");
			const skillsSections = document.getElementById("skills");
			const experienceSection = document.getElementById("experience");

			const scrollPosition = window.scrollY + 200; // offset for better accuracy

			if (
				scrollPosition >= aboutSection.offsetTop &&
				scrollPosition < projectsSection.offsetTop
			) {
				setActive("about");
			} else if (
				scrollPosition >= projectsSection.offsetTop &&
				scrollPosition < skillsSections.offsetTop
			) {
				setActive("projects");
			} else if (
				scrollPosition >= skillsSections.offsetTop &&
				scrollPosition < experienceSection.offsetTop
			) {
				setActive("skills");
			} else if (scrollPosition >= experienceSection.offsetTop) {
				setActive("experience");
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	const scrollToSection = (id) => {
		const section = document.getElementById(id);

		if (section) {
			section.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<React.Fragment>
			<div className="nav-container">
				<nav className="navbar">
					<div className="nav-background">
						<ul className="nav-list">
							<li
								className={
									active === "about"
										? "nav-item active"
										: "nav-item"
								}
								onClick={() =>
									window.scrollTo({
										top: 0,
										behavior: "smooth",
									})
								}
							>
								About
							</li>
							<li
								className={
									active === "projects"
										? "nav-item active"
										: "nav-item"
								}
								onClick={() => scrollToSection("projects")}
							>
								Projects
							</li>
							<li
								className={
									active === "skills"
										? "nav-item active"
										: "nav-item"
								}
								onClick={() => scrollToSection("skills")}
							>
								Skills
							</li>
							<li
								className={
									active === "experience"
										? "nav-item active"
										: "nav-item"
								}
								onClick={() => scrollToSection("experience")}
							>
								Experience
							</li>
						</ul>
					</div>
				</nav>
			</div>
		</React.Fragment>
	);
};

export default NavBar;
