import { Timeline, Panel } from "rsuite";

import "./styles/ct.css";
import "rsuite/dist/rsuite.min.css";

const CT = () => {
	const myMindCheck = (
		<div className="ct-timeline-item">
			<div className="ct-timeline-header">My Mind Check</div>
			<div className="ct-timeline-body">
				Played a pivotal role in a groundbreaking government initiative,
				collaborating on an innovative student mental health web
				application. Prioritising accessibility through WCAG-compliant
				code, I contributed expertise in data structures and algorithm
				optimisation, resulting in a swift and intuitive platform for
				diverse users. This project showcases my talent for translating
				complex ideas into efficient, user-centered solutions, fostering
				positive change.
				<br />
				<br />
				<b>Why it matters:</b> This project marks a significant step
				towards leveraging technology for the greater good. By focusing
				on mental health support for students, we are contributing to a
				healthier and more resilient future generation. My role in this
				initiative underscores my commitment to utilising technical
				skills for meaningful advancements that extend beyond
				traditional software development.
				<ul className="ct-timeline-list">
					<li>
						<b>Security and Storage:</b> Developed and implemented
						secure data storage protocols for sensitive data,
						adhering to government confidentiality regulations.
					</li>
					<li>
						<b>User Experience:</b> Crafted user interface elements
						of the web application, focusing on intuitive interface
						and accessibility for diverse users.
					</li>
				</ul>
			</div>
		</div>
	);

	const bloum = (
		<div className="timeline-item">
			<div className="ct-timeline-header">Bloum</div>
			<div className="ct-timeline-body">
				Combining my deep knowledge of the JavaScript ecosystem with a
				passion for lean and agile processes, I played a pivotal role in
				developing a groundbreaking student analytics and wellbeing
				platform. My contributions helped lead to a remarkable 1200%
				user growth, showcasing my dedication to exceeding expectations
				and pioneering meaningful progress within the software
				development domain.
				<ul className="ct-timeline-list">
					<li>
						<b>
							Shaped the future of EdSaaS with an innovative
							'Student Growth' platform:
						</b>{" "}
						Successfully contributed significantly to an innovative
						Student Growth platform, expanding the traditional scope
						of EdSaaS solutions.
					</li>
					<li>
						<b>Performance Optimisation:</b> Identified and resolved
						critical API bottleneck, leading to a 15% reduction in
						response times and improved user experience.
					</li>
					<li>
						<b>Microservices Architect:</b> Implemented key
						microservices, promoting a maintainable and scalable
						codebase.
					</li>
				</ul>
			</div>
		</div>
	);

	const timeline = (
		<Timeline isItemActive={Timeline.ACTIVE_FIRST}>
			<Timeline.Item>{myMindCheck}</Timeline.Item>
			<Timeline.Item>{bloum}</Timeline.Item>
		</Timeline>
	);

	return <Panel bordered>{timeline}</Panel>;
};

export default CT;
