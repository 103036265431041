import { Timeline, Panel } from "rsuite";

import "./styles/embelton.css";
import "rsuite/dist/rsuite.min.css";

const Embelton = () => {
	const timeline = (
		<Timeline isItemActive={Timeline.ACTIVE_FIRST}>
			<Timeline.Item>
				<div className="timeline-item">
					<div className="emb-timeline-header">Embelton</div>
					<div className="timeline-body">
						At the intersection of business and technology, I have
						honed collaboration skills, expertise in automation, and
						a profound understanding of user experience, cultivated
						during hands-on experience in level 2 helpdesk support.
						I excel at bridging the divide between business
						objectives and technological constraints, driving
						efficiency and fostering growth through inventive
						solutions.
						<ul className="emb-timeline-list">
							<li>
								<b>Project Management:</b> Collaborated
								effectively with the National IT Manager on
								complex projects, demonstrating strong
								communication and teamwork skills. A prime
								example of this collaboration was leading the
								successful rollout of an IT ticketing system,
								aligning its features with defined scope and
								user requirements. This project, informed by
								market research, optimised issue tracking,
								prioritisation, and resolution, ensuring clear
								communication, improved accountability, and a
								more efficient IT operation.
							</li>
							<li>
								<b>Infrastructure Integration:</b> Successfully
								acquired and integrated a subsidiary's IT
								infrastructure, ensuring seamless transition and
								minimising disruption.
							</li>
							<li>
								<b>Technical Expertise:</b> Provided level 2
								helpdesk support, troubleshooting and resolving
								complex IT issues for internal users.
							</li>
							<li>
								<b>Process Automation:</b> Automated internal
								processes through shell scripting, enhancing
								efficiency and accuracy, and reducing manual
								workload.
							</li>
						</ul>
					</div>
				</div>
			</Timeline.Item>
		</Timeline>
	);

	return <Panel bordered>{timeline}</Panel>;
};

export default Embelton;
