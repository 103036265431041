const SEO = [
	{
		page: "home",
		description:
			"I am a full-stack developer proficient in the JavaScript ecosystem, with extensive experience in building scalable, secure, and reliable web applications. I specialize in utilising frameworks and technologies such as React, Node.js, and TypeScript to develop both frontend and backend solutions. My expertise spans across startups and established projects, where I've contributed to the development of scalable applications, ensuring high performance and maintainability throughout the development lifecycle.",
		keywords: [
			"Software Developer",
			"Full Stack Developer",
			"Web Developer",
			"Frontend Developer",
			"Backend Developer",
			"JavaScript Developer",
			"React Developer",
			"Node.js Developer",
			"TypeScript Developer",
			"Responsive Web Design",
			"Mobile-First Development",
			"RESTful APIs",
			"Database Design",
			"Agile Development",
			"Scrum Methodology",
			"Continuous Integration/Continuous Deployment (CI/CD)",
			"Version Control (e.g., Git)",
			"Test-Driven Development (TDD)",
			"User-Centered Design",
			"Cross-Browser Compatibility",
			"Performance Optimization",
			"Scalability",
			"Microservices Architecture",
			"Docker Containers",
			"AWS",
		],
	},
];

export default SEO;
