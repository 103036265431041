import "./styles/experienceList.css";

const ExperienceList = ({ selectedExperience, handleOnClick }) => {
	const ct = (
		<div
			className={
				"exp" + (selectedExperience === "ct" ? " exp-active" : "")
			}
			onClick={() => handleOnClick("ct")}
		>
			<div>
				<img src="./ct.png" alt="ct" className="exp-image" />
			</div>
			<div className="exp-right">
				<div className="exp-title">Centorrino Technologies</div>
				<div className="exp-position">
					<div className="exp-subtitle">Full Stack Developer</div>
					<div className="exp-duration">2021 - Present</div>
				</div>
			</div>
		</div>
	);

	const deliverTrade = (
		<div
			className={
				"exp" +
				(selectedExperience === "delivertrade" ? " exp-active" : "")
			}
			onClick={() => handleOnClick("delivertrade")}
		>
			<div>
				<img
					src="./delivertrade.jpeg"
					alt="delivertrade"
					className="exp-image"
				/>
			</div>
			<div className="exp-right">
				<div className="exp-title">DeliverTrade</div>
				<div className="exp-position">
					<div className="exp-subtitle">Software Developer</div>
					<div className="exp-duration">2021 (Contract)</div>
				</div>
			</div>
		</div>
	);

	const embelton = (
		<div
			className={
				"exp" + (selectedExperience === "embelton" ? " exp-active" : "")
			}
			onClick={() => handleOnClick("embelton")}
		>
			<div>
				<img
					src="./embelton.png"
					alt="embelton"
					className="exp-image"
				/>
			</div>
			<div className="exp-right">
				<div className="exp-title">Embelton</div>
				<div className="exp-position">
					<div className="exp-subtitle">
						IT Administrator /<br></br> Project Coordinator
					</div>
					<div className="exp-duration">2019 - 2021</div>
				</div>
			</div>
		</div>
	);

	return (
		<div className="container">
			{ct}
			{deliverTrade}
			{embelton}
		</div>
	);
};

export default ExperienceList;
