import { Timeline, Panel } from "rsuite";

import "./styles/delivertrade.css";
import "rsuite/dist/rsuite.min.css";

const DeliverTrade = () => {
	const timeline = (
		<Timeline isItemActive={Timeline.ACTIVE_FIRST}>
			<Timeline.Item>
				<div className="timeline-item">
					<div className="dt-timeline-header">Internal Tooling</div>
					<div className="timeline-body">
						Collaborated closely with the CEO to pinpoint
						operational challenges within the company, resulting in
						enhanced efficiency, substantial cost savings, and
						improved return on investment. By liberating resources,
						the team was able to strategically allocate further
						growth in other areas.
						<ul className="dt-timeline-list">
							<li>
								<b>Automated Internal Processes:</b> Eliminated
								manual tasks, reducing workload and human error.
							</li>
							<li>
								<b>Streamlined Workflows:</b> Optimised
								processes for seamless operation, saving time
								and resources.
							</li>
							<li>
								<b>Data Manipulation and Processing:</b>{" "}
								Enhanced data handling capabilities for improved
								decision-making and insights.
							</li>
						</ul>
					</div>
				</div>
			</Timeline.Item>
		</Timeline>
	);

	return <Panel bordered>{timeline}</Panel>;
};

export default DeliverTrade;
