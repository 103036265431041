import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import Logo from "../components/generic/logo";
import NavBar from "../components/generic/navBar";
import About from "../components/homepage/about";
import Experience from "../components/homepage/experience";
import Projects from "../components/homepage/projects";
import Skills from "../components/homepage/skills";
import Waving from "../components/generic/waving";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/homepage.css";

const DEFAULT_LOGO_SIZE = 45.6;

const Homepage = () => {
    const [stayLogo, setStayLogo] = useState(false);
    const [logoSize, setLogoSize] = useState(80);
    const [oldLogoSize, setOldLogoSize] = useState(80);
    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            let scroll = Math.round(window.pageYOffset, 2);

            if (scroll >= 100) {
                setLogoSize(DEFAULT_LOGO_SIZE);
                setStayLogo(true);
                return;
            }

            let newLogoSize = 80 - (scroll * 4) / 10;

            if (newLogoSize < oldLogoSize) {
                if (newLogoSize > 40) {
                    setLogoSize(newLogoSize);
                    setOldLogoSize(newLogoSize);
                    setStayLogo(false);
                } else {
                    setStayLogo(true);
                }
            } else {
                setLogoSize(newLogoSize);
                setStayLogo(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [logoSize, oldLogoSize]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            let newOpacity = 1 - (scrollPosition - 10) / 50;
            newOpacity = Math.max(0, Math.min(1, newOpacity));

            setOpacity(newOpacity);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const currentSEO = SEO.find((item) => item.page === "home");

    const helmet = (
        <Helmet>
            <title>{INFO.main.title}</title>
            <meta name="description" content={currentSEO.description} />
            <meta name="keywords" content={currentSEO.keywords.join(", ")} />
        </Helmet>
    );

    const logoStyle = {
        display: "flex",
        position: stayLogo ? "fixed" : "relative",
        top: stayLogo ? "3vh" : "auto",
        zIndex: 999,
        border: stayLogo ? "1px solid white" : "none",
        borderRadius: stayLogo ? "50%" : "none",
        boxShadow: stayLogo ? "0px 4px 10px rgba(0, 0, 0, 0.25)" : "none",
    };

    const about = (
        <div id={"about"}>
            <About />
        </div>
    );

    const projects = (
        <div id={"projects"} className="homepage-projects">
            <Projects />
        </div>
    );

    const experience = (
        <div id={"experience"} className="homepage-experience">
            <Experience />
        </div>
    );

    const skills = (
        <div id={"skills"} className="homepage-skills">
            <Skills />
        </div>
    );

    return (
        <React.Fragment>
            {helmet}

            <div className="page-content">
                <NavBar />
                <div className="content-wrapper">
                    <div className="homepage-header">
                        <div className="homepage-logo-container">
                            <div style={logoStyle}>
                                <Logo width={logoSize} link={false} />
                            </div>
                        </div>
                        <div
                            style={{ opacity }}
                            className="title homepage-title"
                        >
                            Hi There <Waving />
                        </div>
                    </div>

                    <div className="homepage-container">
                        {about}
                        {projects}
                        {skills}
                        {experience}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Homepage;
